<template>
  <div id="about-us-contact-us" class="light-purple-row">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="title headline-l">
            {{ $t("aboutUsScreen.contactUs.title") }}
          </h1>
        </div>
        <div class="col-12 col-md-6 offset-md-3 mx-lg-auto text-center text">
            {{ $t("aboutUsScreen.contactUs.content") }}
        </div>
        <div class="col-12 text-center">
          <base-button
            :text="$t('aboutUsScreen.contactUs.button')"
            size="large"
            @clicked="handleButtonClick"
          ></base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../../elements/Button.vue";
export default {
  name: "ContactUs",
  props: {},
  components: { BaseButton },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      window.location.href = "mailto:support@blockben.com";
    }
  },
};
</script>

<style lang="scss" scoped>
#about-us-contact-us {
  padding-bottom: $margin-l;
  .title {
    margin-bottom: $margin-s;
    color: $primary-purple;
  }
  .text {
    font-size: 20px;
    font-family: "HKNova-Bold";
    color: $text-color;
    margin-bottom: $margin-m;
  }
}
@media (max-width: $breaking-point-to-md) {
  #about-us-contact-us {
    padding-top: $margin-s !important;
    padding-bottom: 90px !important;
    .left-container {
      margin-top: $margin-m;
    }
  }

  .m-md-auto {
    margin: auto;
  }
}
</style>
