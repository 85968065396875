<template>
  <div id="about-us-legal-information">
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <h1 class="title headline-l">
            {{ $t("aboutUsScreen.legalInformation.title") }}
          </h1>
        </div>
      </div>
      <div class="row text">
        <div class="col-12 col-lg-3 d-none d-lg-block"></div>
        <div class="col-12 col-lg-3 key text-lg-left text-center">
          {{ $t("aboutUsScreen.legalInformation.companyName") }}:
        </div>
        <div class="col-12 col-lg-3 text-lg-right text-center value">
          {{ $t("aboutUsScreen.legalInformation.values.companyName") }}
        </div>
      </div>

      <div class="row text">
        <div class="col-12 col-lg-3 d-none d-lg-block"></div>
        <div class="col-12 col-lg-3 key text-lg-left text-center">
          {{ $t("aboutUsScreen.legalInformation.companyRegistrationNumber") }}:
        </div>
        <div class="col-12 col-lg-3 text-lg-right text-center value">
          {{ $t("aboutUsScreen.legalInformation.values.companyRegistrationNumber") }}
        </div>
      </div>
      <div class="row text">
        <div class="col-12 col-lg-3 d-none d-lg-block"></div>
        <div class="col-12 col-lg-3 key text-lg-left text-center">
          {{ $t("aboutUsScreen.legalInformation.taxNumber") }}:
        </div>
        <div class="col-12 col-lg-3 text-lg-right text-center value">
          {{ $t("aboutUsScreen.legalInformation.values.taxNumber") }}
        </div>
      </div>
      <div class="row text">
        <div class="col-12 col-lg-3 d-none d-lg-block"></div>
        <div class="col-12 col-lg-3 key text-lg-left text-center">
          {{ $t("aboutUsScreen.legalInformation.headquarters") }}:
        </div>
        <div class="col-12 col-lg-3 text-lg-right text-center value">
          {{ $t("aboutUsScreen.legalInformation.values.headquarters") }}
        </div>
      </div>
      <div class="row text">
        <div class="col-12 col-lg-3 d-none d-lg-block"></div>
        <div class="col-12 col-lg-3 key text-lg-left text-center">
          {{ $t("aboutUsScreen.legalInformation.yearOfFoundation") }}:
        </div>
        <div class="col-12 col-lg-3 text-lg-right text-center value">
          {{ $t("aboutUsScreen.legalInformation.values.yearOfFoundation") }}
        </div>
      </div>
      <div class="row text mb-4">
        <div class="col-12 col-lg-3 d-none d-lg-block"></div>
        <div class="col-12 col-lg-3 key text-lg-left text-center">
          {{ $t("aboutUsScreen.legalInformation.before") }}:
        </div>
        <div class="col-12 col-lg-3 text-lg-right text-center value">
          {{ $t("aboutUsScreen.legalInformation.values.before") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LegalInformation",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#about-us-legal-information {
  padding-bottom: $margin-l;

  .title {
    margin-bottom: $margin-m;
    color: $primary-purple;
  }
  .text {
    padding-bottom: 20px;
  }

  .value {
    color: $text-blue;
  }

  .key text-lg-left text-center {
    color: $text-blue;
    font-family: "HKNova-Bold";
  }
}
@media (max-width: $breaking-point-to-md) {
  #about-us-legal-information {
    padding-bottom: 90px !important;

    .key {
      padding-bottom: 20px;
    }
  }

  .m-md-auto {
    margin: auto;
  }
}
</style>


