<template>
  <div id="about-us-our-drive" class="light-purple-row">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="title headline-l">
            {{ $t("aboutUsScreen.ourDrive.title") }}
          </h1>
        </div>
        <div class="col-lg-4 col-12 text-center">
          <img
            class="m-md-auto image"
            src="/images/screens/about-us/hiszunk.svg"
          />
          <div class="headline">
            {{ $t("aboutUsScreen.ourDrive.faith.title") }}
          </div>
          <div class="text">
            {{ $t("aboutUsScreen.ourDrive.faith.description") }}
          </div>
        </div>
        <div class="col-lg-4 col-12 text-center">
          <img
            class="m-md-auto image"
            src="/images/screens/about-us/ujitunk.svg"
          />
          <div class="headline">
            {{ $t("aboutUsScreen.ourDrive.innovation.title") }}
          </div>
          <div class="text">
            {{ $t("aboutUsScreen.ourDrive.innovation.description") }}
          </div>
        </div>
        <div class="col-lg-4 col-12 text-center">
          <img
            class="m-md-auto image"
            src="/images/screens/about-us/alkotunk.svg"
          />
          <div class="headline">
            {{ $t("aboutUsScreen.ourDrive.creation.title") }}
          </div>
          <div class="text">
            {{ $t("aboutUsScreen.ourDrive.creation.description") }}
          </div>
        </div>
        <!-- <div class="col-lg-6 col-12">
          <div class="left-container">
            <h1 class="title headline-l">
              {{ $t("aboutUsScreen.bridge.title") }}
            </h1>
            <div class="text">
              {{ $t("aboutUsScreen.bridge.description_1") }}
            </div>
            <div class="text">
              {{ $t("aboutUsScreen.bridge.description_2") }}
            </div>
            <div class="text text-bold">
              {{ $t("aboutUsScreen.bridge.description_3") }}
            </div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurDrive",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#about-us-our-drive {
  padding-bottom: $margin-l;

  .title {
    margin-bottom: $margin-m;
    color: $primary-purple;
  }

  .headline {
    margin-top: $margin-s;
    margin-bottom: $margin-s;
  }

  .text {
    color: $text-blue;
  }
}
@media (max-width: $breaking-point-to-md) {
  #about-us-our-drive {
    padding-top: $margin-s !important;
    padding-bottom: 90px !important;
    .left-container {
      margin-top: $margin-m;
    }
  }

  .m-md-auto {
    margin: auto;
  }
}
</style>
