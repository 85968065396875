<template>
<div class='roadmap-section py-5'>
	<div class='container'>
		<div class='row text-center'>
			<div class='col-12'>
				<h1 class="headline-l mb-4 title">
					{{ $t("aboutUsScreen.roadmap.title") }}
				</h1>
				<p class="text-l description mx-auto">
					{{ $t("aboutUsScreen.roadmap.description") }}
				</p>
				<div class="flex justify-content-center">
					<base-button
						class="jumbotron-button mx-3"
						:text="$t('aboutUsScreen.roadmap.button2022')"
						:size="'large'"
						@clicked="handleOldButtonClick"
					/>
					<base-button
						class="jumbotron-button mx-3"
						:text="$t('aboutUsScreen.roadmap.button2023')"
						:size="'large'"
						@clicked="handleNewButtonClick"
					/>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'RoadMap',
	methods: {
		handleOldButtonClick(){
			const shortCodeToLangCode = {
				hu: 'hu',
				en: 'eng',
			}
			const link = `http://data.blockben.com/roadmap/blockben_roadmap_${shortCodeToLangCode[this.$i18n.locale || 'en']}.pdf`;
			window.open(link, '_blank');
		},
		handleNewButtonClick(){
			const shortCodeToLangCode = {
				hu: 'HU',
				en: 'ENG',
			}
			const link = `http://data.blockben.com/roadmap/BlockBen_Roadmap2023_${shortCodeToLangCode[this.$i18n.locale || 'EN']}.pdf`;
			window.open(link, '_blank');
		}
	}
};
</script>

<style lang='scss' scoped>
.roadmap-section {
	background-color: $lighter-purple;
	padding-bottom: 80px;
	padding-top: 80px;
	margin-bottom: 90px;

	@media (max-width: $breakpoint-sm) {
		margin-bottom: 0;
	}

	.title {
		color: $primary-purple;
	}

	.description {
		max-width: 730px;
		text-align: center;
		margin-bottom: 48px;

		@media (max-width: $breakpoint-sm) {
			margin-bottom: 65px;
		}
	}
}
</style>
