<template>
  <div class="container">
    <div id="about-us-leaders" class="light-purple-row row">
      <div
        class="person col-lg-3 col-6"
        v-for="(person, index) in personsList"
        :key="index"
      >
        <div>
          <img class="profile-img" :src="person.imgSrc" />
          <div class="name">
            {{ $t(`aboutUsScreen.leaders.name.${person.name}`) }}
          </div>
          <div class="title">
            {{ $t(`aboutUsScreen.leaders.title.${person.name}`) }}
          </div>
          <a :href="person.linkedInUrl" target="_blank">
            <i class="bb-linkedin linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Leaders",
  props: {},
  components: {},
  data() {
    return {
      personsList: [
        {
          imgSrc: "/images/sections/about-us/leaders/istvan-nagy.png",
          name: "IstvanNagy",
          linkedInUrl:
            "https://www.linkedin.com/in/istv%C3%A1n-nagy-dr-356924167/",
        },
        {
          imgSrc: "/images/sections/about-us/leaders/attila-krocsek.png",
          name: "AttilaKrocsek",
          linkedInUrl: "https://www.linkedin.com/in/attila-krocsek-458534162/",
        },
        {
          imgSrc: "/images/sections/about-us/leaders/andras-szabolcsi.png",
          name: "AndrasSzabolcsi",
          linkedInUrl: "https://www.linkedin.com/in/andras-szabolcsi-2295b4b1/",
        },
        {
          imgSrc: "/images/sections/about-us/leaders/viktor-bodnar.png",
          name: "ViktorBodnar",
          linkedInUrl: "https://www.linkedin.com/in/viktor-bodnar-969a4a55/",
        },
        {
          imgSrc: "/images/sections/about-us/leaders/peter-horvath.png",
          name: "PeterHorvath",
          linkedInUrl: "https://www.linkedin.com/in/peterhorvath1/",
        },
        {
          imgSrc: "/images/sections/about-us/leaders/dora-kovacs.png",
          name: "DoraKovacs",
          linkedInUrl: "https://www.linkedin.com/in/kovács-dóra-46a10bb4/",
        },
        {
          imgSrc: "/images/sections/about-us/leaders/zoltan-veszer.png",
          name: "ZoltanVeszer",
          linkedInUrl: "https://www.linkedin.com/in/zoltanveszer/",
        },
      ],
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#about-us-leaders {
  padding-bottom: $margin-l;
  .person {
    display: flex;
    justify-content: space-around;
    margin-bottom: $margin-m;
    .profile-img {
      width: 255px;
      height: 255px;
      margin-bottom: 20px;
      @media (max-width: 1200px) {
        width: 169px;
        height: 169px;
      }
      @media (max-width: 992px) {
        width: 240px;
        height: 240px;
      }
      @media (max-width: 700px) {
        width: 169px;
        height: 169px;
      }
      @media (max-width: 400px) {
        width: 120px;
        height: 120px;
      }
      @media (max-width: 350px) {
        width: 100px;
        height: 100px;
      }
    }
    .name {
      font-size: 20px;
      font-family: "HKNova-Bold";
      color: $text-color;
      line-height: 30px;
      margin-bottom: 4px;
    }
    .title {
      margin-bottom: $margin-s;
      color: $text-blue;
      font-size: $text-l;
    }
    .linkedin {
      font-size: 20px;
      margin-bottom: $margin-l;
    }
  }
}
@media (max-width: $breaking-point-to-md) {
  #about-us-leaders {
    padding-top: $margin-s !important;
    padding-bottom: 90px !important;
    .left-container {
      margin-top: $margin-m;
    }
  }

  .m-md-auto {
    margin: auto;
  }
}
</style>
