<template>
  <div id="about-us-map">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="section-title headline-l">
            {{ $t("aboutUsScreen.map.title") }}
          </h1>
        </div>
        <div class="col-lg-5 col-12 locationButtons">
          <button @click="select(0)" :class="classes(0)">
            <img
              class="pin"
              :src="`/images/sections/about-us/map/${
                selectedLocation === 0 ? 'pin' : 'pin_passive'
              }.svg`"
            />
            <div class="title">
              {{ $t("aboutUsScreen.map.lithuania.title") }}
            </div>
            <div class="description">
              {{ $t("aboutUsScreen.map.lithuania.description") }}
            </div>
          </button>
        </div>
        <div class="col-lg-7 col-12"><div id="map"></div></div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/api";
export default {
  name: "Map",
  props: {},
  components: {},
  data: () => ({ selectedLocation: 0, map: undefined }),
  async mounted() {
    mapkit.init({
      authorizationCallback: async function (done) {
        const response = await Api.get("map/token");
        const body = await response.json();

        done(body.token);
      },
    });
    this.map = new mapkit.Map("map");
    this.showEstonia();
  },
  computed: {},
  methods: {
    select(index) {
      this.selectedLocation = index;
      this.showEstonia();
    },
    showEstonia: function () {
      const MarkerAnnotation = mapkit.MarkerAnnotation;
      const coordinate = new mapkit.Coordinate(
        54.69670247647383, 25.279418313054805
      );
      const region = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(54.69670247647383, 25.279418313054805),
        new mapkit.CoordinateSpan(0.01, 0.02)
      );

      const sfoAnnotation = new MarkerAnnotation(coordinate, {
        color: "#4B2EAA",
      });
      this.map.showItems([sfoAnnotation]);

      this.map.region = region;
    },
    classes(index) {
      if (index === this.selectedLocation) {
        return ["locationButton", "selectedLocationButton"];
      }
      return ["locationButton"];
    },
  },
};
</script>

<style lang="scss" scoped>
#about-us-map {
  padding-top: $margin-l;
  padding-bottom: $margin-l;

  .section-title {
    color: $primary-purple;
    margin-bottom: 70px;
  }

  #map {
    height: 375px;
    width: 100%;
  }

  .locationButton {
    cursor: pointer;
    margin: auto;
    margin-bottom: $margin-m;
    display: block;
    width: 60%;
    background-color: white;
    border: 1px solid $primary-purple;
    box-shadow: 0px 4px 8px rgba(75, 46, 170, 0.1);
    text-align: left;
    padding: 17px;
    border-radius: 10px;
    opacity: 0.6;
    &:hover {
      background: $lighter-purple;
    }
  }

  .selectedLocationButton {
    opacity: 1;
  }

  .pin {
    margin-bottom: 15px;
  }

  .title {
    font-family: "HKNova-Bold";
    font-size: $text-l;
    color: $text-color;
    margin-bottom: 15px;
  }
  .description {
    font-size: $text-m;
    color: $text-blue;
  }
}
@media (max-width: $breaking-point-to-md) {
  #about-us-map {
    padding-top: $margin-s !important;
    padding-bottom: 90px !important;
    .locationButton:first-child {
      margin-right: 20px;
    }

    .locationButton {
      display: inline-block;
      width: calc(50% - 10px);
    }
  }
}
</style>
