<template>
  <div class="introduction-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mb-4 mb-md-0 d-none d-md-block">
          <img
            style="width: 80%;"
            src="/images/screens/about-us/legy_naprakesz.png"
            class="illustration"
          />
        </div>
        <div class="col-12 d-sm-none nopadding">
          <img
            src="/images/screens/about-us/legy_naprakesz.png"
            class="illustration"
          />
        </div>
        <div class="col-12 col-md-6 content-col my-auto">
          <h1 class="headline-l mb-4 mb-md-5 title">
            {{ $t("aboutUsScreen.upToDate.title") }}
          </h1>
          <p class="text-l description">
            {{ $t("aboutUsScreen.upToDate.description") }}
          </p>
          <base-button
            class="jumbotron-button"
            :text="$t('aboutUsScreen.upToDate.button')"
            :size="'large'"
            @clicked="handleButtonClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpToDate",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      window.open("https://blog.blockben.com/", "_blank");
    },
  },
};
</script>

<style lang='scss' scoped>
.introduction-section {
  margin-bottom: 90px;

  .title {
    color: $primary-purple;
  }

  .description {
    margin-bottom: $margin-m;
  }


  @media (max-width: $breakpoint-sm) {
		padding: 0 0 50px;

		.title {
      margin-top: $margin-s;
    }

    .row {
      text-align: center;
    }
  }
}

.content-col {
  p {
    color: $text-blue;
  }
}
</style>
