
<template>
  <div id="about-us-permits">
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <h1 class="title headline-l">
            {{ $t("aboutUsScreen.permits.title") }}
          </h1>
        </div>
        <div class="col-6 mx-auto text">
          {{ $t("aboutUsScreen.permits.description") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Permits",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#about-us-permits {
  padding-bottom: $margin-m;

  .title {
    margin-bottom: $margin-s;
    color: $primary-purple;
  }
  .text {
    font-size: $text-l;
    color: $text-blue;
    line-height: 30px;
    margin-bottom: $margin-s;
  }

  .code {
    font-size: $headline;
    color: $text-color;
    font-family: "HKNova-Bold";
  }
}
@media (max-width: $breaking-point-to-md) {
  #about-us-permits {
    padding-bottom: 90px !important;
    .left-container {
      margin-top: $margin-m;
    }
  }

  .m-md-auto {
    margin: auto;
  }
}
</style>
