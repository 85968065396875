<template>
  <div id="about-us-bridge" class="light-purple-row">
    <div class="container">
      <div class="row flex-md-row-reverse align-items-center">
        <div class="col-lg-6 col-12">
          <img class="m-md-auto" src="/images/screens/about-us/hidakat.svg" />
        </div>
        <div class="col-lg-6 col-12">
          <div class="left-container">
            <h1 class="title headline-l">
              {{ $t("aboutUsScreen.bridge.title") }}
            </h1>
            <div class="text">
              {{ $t("aboutUsScreen.bridge.description_1") }}
            </div>
            <div class="text">
              {{ $t("aboutUsScreen.bridge.description_2") }}
            </div>
            <div class="text text-bold">
              {{ $t("aboutUsScreen.bridge.description_3") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bridge",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#about-us-bridge {
  padding-top: $margin-l;
  padding-bottom: $margin-l;
  .left-container {
    .title {
      margin-bottom: $margin-m;
      color: $primary-purple;
    }
    .text {
      font-size: $text-l;
      color: $text-blue;
      line-height: 30px;
      margin-bottom: $margin-s;
    }
  }
}
@media (max-width: $breaking-point-to-md) {
  #about-us-bridge {
    padding-top: $margin-s !important;
    padding-bottom: 90px !important;
    .left-container {
      margin-top: $margin-m;
    }
  }

  .m-md-auto {
    margin: auto;
  }
}
</style>
