
<template>
  <div class="history-section">
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <h1 class="headline-l mt-5 title">
            {{ $t("aboutUsScreen.history.title") }}
          </h1>
        </div>
        <div class="col-12 history-buttons d-none d-lg-block">
          <history-button
            style="margin-right: 125px"
            :active="active === 'past'"
            type="past"
            @clicked="onSelect('past')"
          ></history-button>
          <history-button
            style="margin-right: 125px"
            :active="active === 'present'"
            type="present"
            @clicked="onSelect('present')"
          ></history-button>
          <history-button
            :active="active === 'future'"
            type="future"
            @clicked="onSelect('future')"
          ></history-button>
        </div>
        <div
          id="mobile-history-buttons"
          ref="mobileHistoryButtons"
          v-resize="onResize"
          class="d-lg-none"
        >
          <div
            class="button-container"
            id="past-button"
            @click="clickMobilePast($event)"
          >
            <history-button
              :active="active === 'past'"
              type="past"
              :mobile="true"
              @clicked="onSelect('past')"
            ></history-button>
            <div class="arrow-container arrow-container-right">
              <img
                class="arrow"
                :src="'/images/sections/about-us/history/right.svg'"
              />
            </div>
          </div>
          <div
            class="button-container"
            id="present-button"
            @click="clickMobilePresent($event)"
          >
            <div class="arrow-container arrow-container-left">
              <img
                class="arrow"
                :src="'/images/sections/about-us/history/left.svg'"
              />
            </div>
            <history-button
              :active="active === 'present'"
              type="present"
              :mobile="true"
              @clicked="onSelect('present')"
            ></history-button>
            <div class="arrow-container arrow-container-right">
              <img
                class="arrow"
                :src="'/images/sections/about-us/history/right.svg'"
              />
            </div>
          </div>
          <div
            class="button-container"
            id="future-button"
            @click="clickMobileFuture($event)"
          >
            <div class="arrow-container arrow-container-left">
              <img
                class="arrow"
                :src="'/images/sections/about-us/history/left.svg'"
              />
            </div>
            <history-button
              :active="active === 'future'"
              type="future"
              :mobile="true"
              @clicked="onSelect('future')"
            ></history-button>
          </div>
        </div>
        <div class="col-12 col-lg-8 offset-lg-2 text-center content" v-html="content"/>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryButton from "@/components/modules/about-us/HistoryButton";
export default {
  name: "History",
  props: {},
  components: { HistoryButton },
  data: () => ({ active: "past" }),
  mounted() {
    this.changeMobileHistoryButtonSize(
      this.$refs.mobileHistoryButtons.getBoundingClientRect().width
    );
  },
  computed: {
    content: function () {
      switch (this.active) {
        case "past":
          return this.$t("aboutUsScreen.history.past.content");
        case "present":
          return this.$t("aboutUsScreen.history.present.content");
        default:
          return this.$t("aboutUsScreen.history.future.content");
      }
    },
  },
  methods: {
    onResize: function ({ width, height }) {
      this.changeMobileHistoryButtonSize(width);
    },
    changeMobileHistoryButtonSize: function (width) {
      var elements = document.getElementsByClassName("button-container");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.width = width + "px";
      }
      document.getElementById("mobile-history-buttons").scrollLeft = 0;
      this.onSelect("past");
    },
    clickMobilePast: function (e) {
      if (this.isRight(e)) {
        this.scrollTo(
          document.getElementById("mobile-history-buttons"),
          this.$refs.mobileHistoryButtons.getBoundingClientRect().width,
          300
        );
        this.onSelect("present");
      }
    },
    clickMobilePresent: function (e) {
      if (this.isLeft(e)) {
        this.scrollTo(
          document.getElementById("mobile-history-buttons"),
          -this.$refs.mobileHistoryButtons.getBoundingClientRect().width,
          300
        );
        this.onSelect("past");
      }
      if (this.isRight(e)) {
        this.scrollTo(
          document.getElementById("mobile-history-buttons"),
          this.$refs.mobileHistoryButtons.getBoundingClientRect().width,
          300
        );
        this.onSelect("future");
      }
    },
    clickMobileFuture: function (e) {
      if (this.isLeft(e)) {
        this.scrollTo(
          document.getElementById("mobile-history-buttons"),
          -this.$refs.mobileHistoryButtons.getBoundingClientRect().width,
          300
        );
        this.onSelect("present");
      }
    },
    isLeft: function (e) {
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left;
      return rect.width / 2 > x;
    },
    isRight: function (e) {
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left;
      return rect.width / 2 < x;
    },
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();

        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        }
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },

    onSelect: function (button) {
      this.active = button;
    },
  },
};
</script>
 
<style lang='scss' scoped>
.history-section {
  background-color: $lighter-purple;

  .button-container {
    display: inline-block;
    position: relative;
  }

  .arrow-container {
    display: inline-block;
    position: absolute;
    top: calc(198px / 2 - 10px);
  }

  .arrow-container-left {
    left: 20px;
  }

  .arrow-container-right {
    right: 20px;
  }

  .arrow {
    font-size: 20px;
  }
  .title {
    color: $primary-purple;
    margin-bottom: $margin-l;
  }

  .description {
    margin-bottom: $margin-m;
  }

  .illustration {
    margin: auto;
  }

  .history-buttons {
    margin-bottom: $margin-l;
  }

  #mobile-history-buttons {
    height: 194px;
    margin-bottom: $margin-m;
    overflow-x: hidden;
    width: auto;
    white-space: nowrap;
  }

  .content {
    color: $text-blue;
    margin-bottom: $margin-l;
  }

  @media (max-width: $breakpoint-sm) {
    padding: 0 50px;

    .title {
      margin-bottom: $margin-m;
    }

    .history-buttons {
      margin-bottom: $margin-m;
    }

    .content {
      margin-bottom: $margin-m;
    }
  }

  @media (max-width: $breakpoint-xs) {
    padding: 0;
  }
}

.content-col {
  p {
    color: $text-blue;
  }
}
</style>