<template>
  <button @click="handleClick" :class="classes">
    <img
      :src="`/images/sections/about-us/history/${type}_${
        active ? 'active' : 'inactive'
      }.svg`"
    />
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "HistoryButton",
  props: {
    type: {
      type: String,
      validator: (val) => ["past", "present", "future"].includes(val),
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({}),
  created() {},
  computed: {
    text: function () {
      switch (this.type) {
        case "past":
          return this.$t("aboutUsScreen.history.past.button");
        case "present":
          return this.$t("aboutUsScreen.history.present.button");
        default:
          return this.$t("aboutUsScreen.history.future.button");
      }
    },
    classes: function () {
      return [
        this.active ? "active" : "default",
        "history-button",
        this.mobile ? "md" : "lg",
      ];
    },
  },
  methods: {
    handleClick() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang='scss' scoped>
.lg {
  width: 160px;
  height: 130px;
}

.md {
  width: 256px;
  height: 194px;
}

.history-button {
  border-radius: 10px;
  cursor: pointer;
  font-family: "HKNova-Bold" !important;
  font-size: 20px;
  box-shadow: 0px 4px 8px rgba(75, 46, 170, 0.1);

  & img {
    margin: auto;
    margin-bottom: 20px;
  }
}

.active {
  color: $white;
  background: $primary-purple;
  border: 1px solid $light-purple;
}

.default {
  color: $text-color;
  background: linear-gradient(
    111.11deg,
    #ffffff -3.09%,
    #fefefe 53.29%,
    #f7f7f7 103.03%
  );
  border: 1px solid $lighter-purple;

  &:hover {
    background: $light-purple;
    border: 1px solid $primary-purple;
  }
}
</style>
