<template>
<div id="about-us-partners">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="title headline-l">
          {{ $t("aboutUsScreen.partners.title") }}
        </h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-2 col-md-4 d-flex align-items-center justify-content-center partner-col">
        <a href="https://aws.amazon.com/what-is-cloud-computing">
          <img src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing">
        </a>
      </div>
      <div class="col-lg-2 col-md-4 d-flex align-items-center justify-content-center partner-col" v-for="partner in partners">
        <img :src="imageUrl(partner.name)" :alt="partner.alt">
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Partners",
  data(){
    return {
      imgSrc: '/images/sections/about-us/partners/',
      partners: [
        {
          name: 'loomis.svg',
          alt: 'Loomis',
        },
        {
          name: 'adamsky.svg',
          alt: 'Positive Adamsky',
        },
        {
          name: 'fireblocks.svg',
          alt: 'Fireblocks',
        },
        // {
        //   name: 'bakertilly.svg',
        //   alt: 'Bakertilly',
        // },
        {
          name: 'nest.svg', // INFO: .png is better quality for smaller icon size
          alt: 'Nest',
        },
      ]
    }
  },
  methods: {
    imageUrl(imageName){
      return `${this.imgSrc}${imageName}`;
    }
  }
}
</script>

<style scoped lang="scss">
#about-us-partners {
  padding-bottom: $margin-l;
  .title {
    margin-bottom: $margin-m;
    color: $primary-purple;
  }

  @media screen and (max-width: $breaking-point-to-md) {
    .partner-col {
      margin-bottom: 50px !important;
    }
  }

  @media screen and (max-width: $breaking-point-to-sm) {
    .partner-col {
      margin-bottom: 79px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }


}
</style>
